<template>
	<Nav />
	<div class="content-wrapper">
		<ContentHeader v-if="textTitle !== ''" :text="textTitle" :addBtn="addBtn" />
		<div v-if="this.currentRouteName !== 'home'" class="content">
			<div class="container-fluid">
				<div v-if="alert.message" :class="`alert ${alert.type}`">
					{{ alert.message }}
				</div>
				<div class="row">
					<div class="col-12">
						<div class="card">
							<div class="card-body">
								<router-view v-on:onChildInit="onChildInit" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</div>
		<Dashboard v-else />
	</div>
</template>

<script>
	import { mapState, mapActions } from "vuex";
	import Nav from "./Nav.vue";
	import ContentHeader from "./ContentHeader.vue";
	import Dashboard from "../dashboard/Dashboard.vue";
	export default {
		components: {
			Nav,
			ContentHeader,
			Dashboard,
		},
		computed: {
			...mapState({
				alert: (state) => state.alert,
			}),
			currentRouteName() {
				return this.$route.name;
			},
		},
		data() {
			return {
				textTitle: "",
				addBtn: null,
			};
		},
		methods: {
			...mapActions({
				clearAlert: "alert/clear",
			}),
			onChildInit(value, addBtn = null) {
				document.title = value;
				this.textTitle = value;
				this.addBtn = addBtn;
			},
		},
		watch: {
			$route(nextState) {
				// clear alert on location change
				this.textTitle = "";
				this.addBtn = undefined;
				if (nextState.name !== "login") {
					this.clearAlert();
				}
			},
		},
	};
</script>
<style>
	.form-group.required label:after {
		color: #d00;
		content: "*";
		margin-left: 5px;
	}
</style>
